import React from "react";

export const WrkShp = ({ title, image, content}) => {
  return (
    <div className="workCard">
      <div className="imageholderWrk">
        <img src={image} alt={title} className="workShopImage" />
      </div>

      <div className="contentWorkShop">
        <h1>{title}</h1>
        <div
          style={{ textAlign: "", lineHeight: "3rem" }}
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
    </div>
  );
};
