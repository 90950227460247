import React, { useState, useEffect } from "react";
import "./os.css";

export const OpenStudio = () => {
  const url = process.env.REACT_APP_SERVER;

  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await fetch(`${url}/get-open_studio`);
        if (res.ok) {
          const parsed = await res.json();
          return setData(parsed);
        } else {
          throw new Error();
        }
      } catch (error) {
        return alert("Internal Error");
      }
    };

    getData();
  }, [url]);

  return (
    <div className="mainOsBox">
      {/* map data */}
      {data?.map((item, idx) => (
        <div className="osBg" style={{backgroundImage:`url("${url}${item?.image}")`}} key={idx}>
          <div className="osTxt" dangerouslySetInnerHTML={{__html:item?.text}}></div>
        </div>
      ))}
    </div>
  );
};
