import React, { useEffect, useState } from "react";
import "./subpage.css";
import { getSub } from "../GlobalVars/GetFunction";
import { TopSubBox } from "../ReUsables/TobSubBox";

export const SubPage = ({ link, type }) => {
  const url = process.env.REACT_APP_SERVER;
  const [data, setData] = useState();

  useEffect(() => {
    getSub(`${type}-${link}`, setData, url);
  }, [link, url, type]);

  return (
    <div className="main">
      <TopSubBox
        title={data?.title}
        image={`${url}${data?.image}`}
        topic={type}
        style={{ maxWidth: "800px", margin: "0 auto" }}
      />
      <div
        dangerouslySetInnerHTML={{ __html: data?.content }}
        style={{
          width: "calc(80% - 4rem)",
          padding: "0 calc(10% + 2rem)",
          fontSize: "2rem",
          lineHeight: "3rem",
          maxWidth: "800px",
          margin: "0 auto",
        }}
        className="embededContent"
      />
    </div>
  );
};
