import React, { useContext, useState, useEffect } from "react";
import "./mainprof.css";
import { UserNav } from "../Nav/UserNav";
import { Changes } from "../GlobalVars/GlobalVars";
import { useNavigate } from "react-router-dom";

export const MainProf = ({ status, link, addiStyle }) => {
  const { userData, setUserData, setLogin } = useContext(Changes);
  const nav = useNavigate();

  const [transformedUrl, setTransformedUrl] = useState("");

  const handleLogOut = async () => {
    sessionStorage.clear();
    await setUserData({ token: "", data: "" });
    await setLogin("login");
    return nav("/");
  };
  useEffect(() => {
    const transformUrl = () => {
      if (status === "Files") {
        const base = link
          ?.split("/drive/folders/")
          ?.join("/embeddedfolderview?id=")
          ?.split("?usp")[0];
        setTransformedUrl(`${base}#list`);
      } else {
        setTransformedUrl(link);
      }
    };

    transformUrl();
  }, [status, link]);

  console.log(transformedUrl);
  return (
    <div className="mainProf">
      <UserNav />
      <div className="userProfBox">
        <div className="profileHolder">
          <h1>Client Portal</h1>
          <button className="profIndetifier">
            {userData?.data?.email?.charAt(0)}
            <div className="profControl">
              <div className="buttonsHolderOfProf">
                <br />
                <font>{userData?.data?.email}</font>
                <br />
                <br />
                <button className="logout" onClick={handleLogOut}>
                  Logout
                </button>
                <br />
                <br />
              </div>
            </div>
          </button>
        </div>
        <br />
        <br />
        <h2>{status || "Profile"}</h2>
        {link ? (
          <iframe
            className="iframe"
            src={transformedUrl}
            style={addiStyle}
            frameBorder={0}
            title={status}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
