import React, { useEffect, useState } from "react";
import "./community.css";
import { TopBox } from "../ReUsables/TopBox";
import { getFunction, getList } from "../GlobalVars/GetFunction";
import { CommunityCard } from "../ReUsables/CommunityCard";
import { Load } from "../ReUsables/Load";

export const CommunityPage = () => {
  const url = process.env.REACT_APP_SERVER;

  const [data, setData] = useState();
  const [comms, setComms] = useState([]);

  useEffect(() => {
    getFunction("community", setData, url);

    const getComm = async () => {
      const comms = await getList("community-list", 0, url);
      setComms(comms);
    };

    getComm();
  }, [url]);

  return (
    <div className="main">
      <TopBox
        title={data?.unstructs?.title}
        image={`${url}${data?.unstructs?.primaryImage}`}
      />

      <div
        className="innerContents"
        dangerouslySetInnerHTML={{ __html: data?.unstructs?.subtitle }}
      ></div>

      <div className="communityHolder">
        {comms?.map((item, index) => (
          <CommunityCard data={item} key={index} />
        ))}
      </div>
      <br />
      <br />
    </div>
  );
};
