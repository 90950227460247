import React, { useEffect, useState } from "react";
import "./reuse.css";
import { FaCalendar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const url = process.env.REACT_APP_SERVER;
export const BlogCard = ({ data, grid, set }) => {
  const nav = useNavigate();

  const [screen, setScreen] = useState(window.screen.width);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreen(window.screen.width);
    });
    if (screen < 750) {
      set(false);
    }
  }, [screen, set]);

  console.log(data)
  return (
    <div className={grid ? "blogGridC" : "blogListC"}>
      <img src={`${url}${data?.image}`} alt="blog" className="blogCardimg" />
      <div className="blogCardContent">
        <p>
          <FaCalendar className="blogDate" />
          &nbsp;&nbsp; <strong>{data?.date?.split("T")[0]}</strong>
        </p>
        <h2>{data?.title}</h2>
        <div dangerouslySetInnerHTML={{__html: data?.content?.split(" ")?.slice(0, 15)?.join(" ")+"..."}}></div>

        <button
          className="readMore"
          onClick={() => {
            nav(`/blog/${data?._id}`);
          }}
        >
          Read More
        </button>
      </div>
    </div>
  );
};
