import React, { useContext, useEffect, useState } from "react";
import "./foot.css";
import { NavLink, useLocation } from "react-router-dom";
import { Changes } from "../GlobalVars/GlobalVars";
import { getFunction } from "../GlobalVars/GetFunction";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
export const Footer = () => {

    const [data, setData] = useState();
    const {change} = useContext(Changes);
    const url = process.env.REACT_APP_SERVER;
    const loc = useLocation();



    useEffect(()=>{
        getFunction("footer", setData, url);
    }, [change, url])

  return (
    <div className={`footer ${loc?.pathname?.includes("/profile") && "profFoot"}`}>
      {/* logo */}
      <div className="footing">
        <img src="/image/logo.png" className="logo" alt="" />
        <br />
        <br />
        © 2024 Karnor Carpet Manufacturers.
        <br />
        All rights reserved
      </div>
      {/* quickies */}
      <div className="footing">
        <h3>Quick Links</h3>
        <NavLink to="/">Home</NavLink>
        <br />
        <br />
        <NavLink to="/services">Services</NavLink>
        <br />
        <br />
        <NavLink to="/about">About</NavLink>
        <br />
        <br />
        <NavLink to="/contact">Contact</NavLink>
      </div>
      {/* contacts */}
      <div className="footing">
        <h3>Contact</h3>
        {data?.unstructs?.phone}<br/><br/>
        {data?.unstructs?.mail}
      </div>
      {/* location */}
      <div className="footing">
        <h3>Location</h3>
        {data?.unstructs?.location}
      </div>

      <div className="footing footLinks">
        <a href={data?.unstructs?.facebook} className="socialLink"><FaFacebook className="socialLink"/></a>
        <a href={data?.unstructs?.linkedin} className="socialLink"><FaLinkedin className="socialLink"/></a>
        <a href={data?.unstructs?.youtube} className="socialLink"><FaYoutube className="socialLink"/></a>
        <a href={data?.unstructs?.twitter} className="socialLink"><FaInstagram className="socialLink"/></a>
        
      </div>
    </div>
  );
};
