import React from "react";
import "./reuse.css";
import { FaHandshake, FaHome, FaPencilRuler, FaStore } from "react-icons/fa";
import { FaPeopleCarryBox } from "react-icons/fa6";

export const IndustryCard = ({ data, index }) => {
  const icons = [
    <FaStore className="icon" />,
    <FaPencilRuler className="icon" />,
    <FaPeopleCarryBox className="icon" />,
    <FaHome className="icon" />,
    <FaHandshake className="icon" />,
  ];
  return (
    <div className="industryCard">
      <div className="contentOfCard">
        {icons[index]}
        <h2 style={{ color: "dimgray", fontWeight: "400" }}>{data?.title}</h2>
      </div>
      
    </div>
  );
};
