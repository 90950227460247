import React, { useContext } from "react";
import "./home.css";
import { HomeData } from "../GlobalVars/GlobalVars";
import { ServCard } from "../ReUsables/ServCard";
import { useNavigate } from "react-router-dom";

export const Serv = ({ url }) => {
  const { data } = useContext(HomeData);
  const nav = useNavigate();
  return (
    <div className="Serv">
      <h1>OUR SERVICES</h1>
      <br />
      <div className="servHolder homeSev">
        {data?.service?.map((item, index) => (
          <ServCard key={index} data={item} url={url} style={{
            margin:"2rem"
          }} />
        ))}
      </div>
      <button className="seemore" onClick={()=>{nav("/services")}}>
        See More
      </button>
    </div>
  );
};
