import React, { useContext } from "react";
import "./reAdmin.css";
import { MdDelete } from "react-icons/md";
import { deleteUnits } from "../GlobalVars/AdminPost";
import { Changes } from "../GlobalVars/GlobalVars";
export const ServAdminCard = ({ data, url, change, setChange, relation }) => {
  const { userData } = useContext(Changes);

  return (
    <div className="servCards adminCard" style={{ margin: "2REM" }}>
      <button
        className="adminDel"
        onClick={async () => {
          await deleteUnits(
            relation === "service" ? "services" : "product",
            data,
            userData?.token
          );
          return setChange(!change);
        }}
      >
        <MdDelete />
      </button>
      <div className="imageHolderServ">
        <img src={url + data?.image} alt="" className="servImg" />
      </div>
      <div className="textOfServ">
        <h3>{data?.title}</h3>
      </div>
    </div>
  );
};
