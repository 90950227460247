import React, { useRef, useState } from "react";
import "./comp.css";
import { sendImage } from "../../../GlobalVars/AdminPost";
import { UrlForm } from "./UrlForm";

export const SlideEditor = ({ data }) => {
  const url = process.env.REACT_APP_SERVER;

  const refList = [useRef(null), useRef(null), useRef(null)];
  const [image, setImage] = useState({
    img1: "",
    img2: "",
    img3: "",
  });

  const newData = (data?.length === 3 && data) || ["", "", ""];

  const [open, setOpen] = useState(false);
  const [slide, setSlide] = useState(0);

  return (
    <div className="SlideEditor">
      {newData?.map((item, idx) => (
        <div className="imageContainer" key={idx}>
          <img src={image[`img${idx + 1}`] || `${url}${item?.image}`} alt="" />
          <div className="bgColor">
            <input
              type="file"
              style={{ display: "none" }}
              ref={refList[idx]}
              onChange={(e) => {
                sendImage(e, "slide", true, idx, setImage);
              }}
            />
            <font
              className="change"
              onClick={() => {
                refList[idx]?.current?.click();
              }}
            >
              Change Image
            </font>

            <button
              className="urls"
              onClick={() => {
                setOpen(true);
                setSlide(idx);
              }}
            >
              Enter Link
            </button>
          </div>
        </div>
      ))}

      <UrlForm set={open} reset={setOpen} idx={slide} val={data} />
    </div>
  );
};
