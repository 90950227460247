import React from "react";
import "./reuse.css";

export const Paras = ({ data }) => {
  return (
    <div className="paragraph">
      <h3>{data?.title}</h3>
      <div  style={{textAlign:"left"}}dangerouslySetInnerHTML={{ __html: data?.content }}></div>
    </div>
  );
};
