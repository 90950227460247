import React, { useContext, useEffect, useState } from "react";
import { AdminTopHandle } from "../../ReusableAdmin/AdminTopHandle";
import { Changes } from "../../GlobalVars/GlobalVars";
import { getFunction } from "../../GlobalVars/GetFunction";
import { ChangeAbt } from "./ChangeAbt";

export const EditAbt = () => {
  const [data, setData] = useState();
  const url = process.env.REACT_APP_SERVER;
  const { change } = useContext(Changes);

  console.log(data);

  useEffect(() => {
    getFunction("about", setData, url);
  }, [url, change]);

  return (
    <div className="adminEdit">
      <AdminTopHandle data={data?.unstructs?.primaryImage} relate={"about"} />
      <br />

      <div className="innerContents">
        <h1 style={{ color: "var(--darkblue)" }}>About</h1>
      </div>

      {/* breaking line */}
      <br />
      <br />
      <br/>
      <ChangeAbt data = {data?.about}/>
    </div>
  );
};
