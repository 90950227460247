import React, { useRef, useState } from "react";
import "./reAdmin.css";
import { sendImage } from "../GlobalVars/AdminPost";

export const AdminTopHandle = ({ data , relate}) => {
  const [blob, setBlob] = useState();
  const url = process.env.REACT_APP_SERVER;

  const ref = useRef(null);

  const style = {
    fontSize: "4rem",
    color: "white",
    fontWeight: "500",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  const changeHandler = async (e) => {
    const { value } = e?.target;
    if (!value) {
      return;
    }

    sendImage(e, relate, false, 0, setBlob);
  };

  return (
    <div className="adminTopBox">
      <img src={blob || url + data} alt="" className="adminTopImg" />
      <div
        className="transBgBlack"
        onClick={() => {
          ref.current.click();
        }}
      >
        <input
          type="file"
          style={{ display: "none" }}
          onChange={changeHandler}
          ref={ref}
        />
        <font style={style}>Change Image</font>
      </div>
    </div>
  );
};
