import React from "react";
import "./nav.css";
import { NavLink } from "react-router-dom";
import { RxUpdate } from "react-icons/rx";
import {  FaFileAlt, FaTag } from "react-icons/fa";
import { IoMdArrowBack } from "react-icons/io";

export const UserNav = () => {
  return (
    <div className="usernav">
      <center>
        <img src="/image/logo.png" alt="" />
      </center>
      <br />
      <NavLink to="/profile" className={"useLink"}>
        <button className="adminNavigation" style={{ display: "flex" }}>
          <RxUpdate style={{ fontSize: "2rem" }} /> &nbsp; Status
        </button>
      </NavLink>
      
      <br />
      <br />
      <NavLink to="/profile/files" className={"useLink"}>
        <button className="adminNavigation" style={{ display: "flex" }}>
          <FaFileAlt style={{ fontSize: "2rem" }} /> &nbsp; Files
        </button>
      </NavLink>
      
      <br />
      <br />
      <NavLink to="/profile/price-rates" className={"useLink"}>
        <button className="adminNavigation" style={{ display: "flex" }}>
          <FaTag style={{ fontSize: "2rem" }} /> &nbsp; Price Rates
        </button>
      </NavLink>

      <br />
      <br />
      <NavLink to="/" className={"useLink"}>
        <button className="adminNavigation" style={{ display: "flex" }}>
          <IoMdArrowBack style={{ fontSize: "2rem" }} /> &nbsp; Back To Website
        </button>
      </NavLink>
    </div>
  );
};
