import React, { useContext } from "react";
import "./home.css";
import { HomeData } from "../GlobalVars/GlobalVars";
import { useNavigate } from "react-router-dom";

export const Products = ({ url }) => {
  const { data } = useContext(HomeData);
  const nav = useNavigate();

  console.log(data)

  return (
    <div className="Serv" style={{background:"rgb(230, 235, 241)"}}>
      <h1>OUR PRODUCTS</h1>
      <br />
      <div className="servHolder homeSev">
        {data?.poduct?.map((item, index) => (
          <button
            className="seemore"
            onClick={() => {
              nav("/our-products");
            }}
            style={{
                margin:" 2rem 2.5rem 4rem 2.5rem"
            }}
            key={index}
          >
            {item?.title}
          </button>
        ))}
      </div>
    </div>
  );
};
