import React, { useContext, useEffect, useState } from "react";
import { AdminTopHandle } from "../../ReusableAdmin/AdminTopHandle";
import { Changes } from "../../GlobalVars/GlobalVars";
import { getFunction } from "../../GlobalVars/GetFunction";
import { AddBtn } from "../../ReusableAdmin/AddBtn";
import { BlogEditableCard } from "../../ReusableAdmin/BlogEditableCard";

export const EditBlog = () => {
  const [data, setData] = useState();
  const [list, setList] = useState();
  const url = process.env.REACT_APP_SERVER;
  const { change, setChange } = useContext(Changes);

  console.log(data);

  useEffect(() => {
    getFunction("blog", setData, url);
    getFunction("blogs-admin", setList, url)
  }, [url, change]);

  return (
    <div className="adminEdit">
      <AdminTopHandle
        data={data?.unstructs?.primaryImage}
        relate={"blog"}
      />
      <br />

      <div className="innerContents">
        <h1 style={{ color: "var(--darkblue)" }}>Blogs</h1>
        <h2>Explore Our Latest Blogs</h2>
      </div>

      {/* breaking line */}
      <br />
      <br />

      {/* mapping services card */}

      <AddBtn linkTo={"/profile/add-blog"} />
      <div
        className="servHolder"
        style={{ margin: "0 auto", width: "calc(80%)", textAlign: "center" }}
      >
        {list?.dataLists?.map((item, index) => (
          <BlogEditableCard
            data={item}
            url={url}
            change={change}
            setChange={setChange}
            key={index}
            style={{backgroundColor:"white", color:"black"}}
            relation={"blog"}
          />
        ))}
      </div>
    </div>
  );
};
