import React, { useContext, useState } from "react";
import "./reuse.css";
import { NavLink, useNavigate } from "react-router-dom";
import { PostFunctions } from "../GlobalVars/PostFunctions";
import { Changes } from "../GlobalVars/GlobalVars";

// login form
export const LoginForm = () => {
  const [logInfo, setLogInfo] = useState({
    email: "",
    password: "",
  });

  const { setUserData, setLogin } = useContext(Changes);

  const nav = useNavigate();

  const redirect = async (userInfos) => {
    await setUserData(userInfos);  
    sessionStorage.setItem("userdata", JSON.stringify(userInfos));
    await setLogin("profile");
    return nav("/profile");
  };

  const submitData = (e) => {
    e.preventDefault();
    PostFunctions("login", logInfo, redirect);
  };
  return (
    <form className="loginForm" onSubmit={submitData}>
      <h1>Login</h1>
      <h3>Welcome back! please enter your details.</h3>
      <br />
      {Object.keys(logInfo).map((item, idx) => (
        <input
          type={item}
          name={item}
          className="loginInput"
          value={logInfo[item]}
          onChange={(e) => {
            const { name, value } = e?.target;
            setLogInfo((prev) => ({ ...prev, [name]: value }));
          }}
          key={idx}
          placeholder={`Enter ${item}`}
          required
        />
      ))}
      <br />
      <button
        type="submit"
        className="submit"
        style={{
          width: "calc(100%)",
          margin: "1.5rem 0",
          padding: "1.5rem 1rem",
          fontSize: "2rem",
        }}
      >
        Login
      </button>
      <br />
      <center>Or</center>
      <br />
      <NavLink to="/register">
        <button className="reg">Register Now</button>
      </NavLink>
    </form>
  );
};

//   registration form
export const RegForm = () => {
  const [logInfo, setLogInfo] = useState({
    email: "",
    password: "",
    rePassword: "",
  });

  const nav = useNavigate();

  const redirect = async () => {
    return nav("/login");
  };

  const submitData = (e) => {
    e.preventDefault();
    PostFunctions("register", logInfo, redirect);
  };
  return (
    <form className="loginForm" onSubmit={submitData}>
      <h1>Register</h1>
      <h3>Join with us today!</h3>
      <br />
      {Object.keys(logInfo).map((item, idx) => (
        <input
          type={item}
          name={item}
          className="loginInput"
          value={logInfo[item]}
          onChange={(e) => {
            const { name, value } = e?.target;
            setLogInfo((prev) => ({ ...prev, [name]: value }));
          }}
          key={idx}
          placeholder={
            item === "rePassword" ? "Re-enter Password" : `Enter ${item}`
          }
          required
        />
      ))}
      <br />
      <button
        type="submit"
        className="submit"
        style={{
          width: "calc(100%)",
          margin: "1.5rem 0",
          padding: "1.5rem 1rem",
          fontSize: "2rem",
        }}
      >
        Register
      </button>
      <br />
      <center>Or</center>
      <br />
      <NavLink to="/login">
        <button className="reg">Login</button>
      </NavLink>
    </form>
  );
};
