import React, { useContext, useEffect, useRef, useState } from "react";
import "./comp.css";
import "react-quill/dist/quill.snow.css";
import { editUnits, sendImage } from "../../../GlobalVars/AdminPost";
import { Changes } from "../../../GlobalVars/GlobalVars";
import JoditEditor from "jodit-react";

const url = process.env.REACT_APP_SERVER;

export const EditHomeAbt = ({ data }) => {
  const [quill, setQuill] = useState({
    addiContent: "",
  });

  const ref = useRef(null);

  const { userData } = useContext(Changes);

  useEffect(() => {
    if (data?.addiContent) {
      setQuill({ addiContent: data?.addiContent });
    }
  }, [data]);

  useEffect(() => {
    if (quill.addiContent && userData?.token) {
      editUnits("home", quill, userData?.token);
    }
  }, [quill, userData?.token]);

  const [blob, setBlob] = useState("");

  const imageIsChanged = async (e) => {
    const file = e?.target?.files[0];
    if(!file){
        return
    }
    const ext = await file?.name?.split(".")?.pop()?.toLowerCase();
    if (ext !== "jpg" && ext !== "png" && ext !== "jpeg") {
      return alert("Invalid File Type!");
    }
    sendImage(e, "home", false, 0, setBlob)
  };

  return (
    <div className="EditHomeAbt">
      <div className="abtHomeBoxEdit">
        <JoditEditor
          value={quill?.addiContent}
          onChange={(value) => {
            setQuill({ addiContent: value });
          }}
          className="quillAbt"
          style={{ width: "100%", height: "60rem" }}
        />
      </div>
      <br />
      <div className="abtHomeBoxEdit" style={{ margin: "0rem 0 " }}>
        <div
          style={{
            backgroundImage: `url("${blob || `${url}${data?.primaryImage}`}")`,
          }}
          alt=""
          className="isimage"
        >
            <input type="file" style={{display:"none"}} ref={ref} onChange={imageIsChanged}/>
          <div className="absBg" onClick={()=>{ref?.current?.click()}}>
            <font className={"changeImg"}>Change Image</font>
          </div>
        </div>
      </div>
    </div>
  );
};
