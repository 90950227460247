import React, { useContext, useEffect, useState } from "react";
import "./comp.css";
import { editUnits } from "../../../GlobalVars/AdminPost";
import { Changes } from "../../../GlobalVars/GlobalVars";

export const ExpEditor = ({ data }) => {
  const [exps, setExps] = useState({
    experience: "",
    happyClients: "",
  });

  const { userData } = useContext(Changes);

  useEffect(() => {
    setExps({
      experience: data?.experience,
      happyClients: data?.happyClients,
    });
  }, [data]);

  useEffect(() => {
    const { experience, happyClients } = exps;

    if (experience && happyClients && userData?.token)
      editUnits("home", exps, userData?.token);
  }, [exps, userData]);

  return (
    <form className="ExpEditor">
      <div
        className="inputHold"
        style={{
          color: "white",
          fontSize: "5rem",
          textAlign: "center",
          width: "fitContent",
        }}
      >
        +
        <input
          type="text"
          className="adminInput"
          value={exps?.experience}
          style={{ color: "white", fontSize: "5rem", width: "8rem" }}
          onChange={(e) => {
            setExps((prev) => ({ ...prev, experience: e?.target?.value }));
          }}
        />
        years
        <br />
        <p style={{ fontSize: "2rem", fontWeight: "600" }}>EXPERIENCE</p>
      </div>
      <div
        className="inputHold"
        style={{
          color: "white",
          fontSize: "5rem",
          textAlign: "center",
          width: "fitContent",
        }}
      >
        +
        <input
          type="text"
          className="adminInput"
          value={exps?.happyClients}
          style={{ color: "white", fontSize: "5rem", width: "10rem" }}
          onChange={(e) => {
            setExps((prev) => ({ ...prev, happyClients: e?.target?.value }));
          }}
        />
        k
        <br />
        <p style={{ fontSize: "2rem", fontWeight: "600" }}>Happy Clients</p>
      </div>
    </form>
  );
};
