import React, { useContext, useEffect, useState } from 'react';
import "../admin.css";
import { getFunction } from '../../GlobalVars/GetFunction';
import { SlideEditor } from './components/SlideEditor';
import { Changes } from '../../GlobalVars/GlobalVars';
import { ExpEditor } from './components/ExpEditor';
import { EditHomeAbt } from './components/EditHomeAbt';

export const EditHome=()=>{
    const [data, setData] = useState();
    const url = process.env.REACT_APP_SERVER;
    const {change} = useContext(Changes);



    useEffect(()=>{
        getFunction("home", setData, url)
    },[url, change]);

    return(

        <div className="adminEdit">
            {/* edit home bg */}
            <SlideEditor data={data?.unstructs?.slides}/>

            <ExpEditor data={data?.unstructs}/>

            <EditHomeAbt data={data?.unstructs}/>
            
            
        </div>
    )
}