import React, { useContext,  useRef, useState } from "react";
import "./fulladd.css";
import JoditEditor from "jodit-react";
import { Changes } from "../GlobalVars/GlobalVars";
import { useNavigate } from "react-router-dom";

export const FullAdd = ({ isReq, page, relation }) => {
  const [data, setData] = useState({
    title: "",
    content: "",
    image: "",
  });

  const url = process.env.REACT_APP_SERVER;

  const nav = useNavigate();

  const image = useRef(null);
  const [blob, setBlob] = useState("");

  const imageHandler = async (e) => {
    const file = e?.target?.files[0];
    if (!file) {
      return;
    }
    const ext = await file?.name?.split(".")?.pop()?.toLowerCase();

    const isInValid = ext !== "jpg" && ext !== "jpeg" && ext !== "png";
    if (isInValid) {
      e.target.files = [];
      return alert("Invalid File Type");
    } else {
      setData((prev) => ({ ...prev, image: file }));
      return setBlob(URL.createObjectURL(file));
    }
  };

  const { userData } = useContext(Changes);

  const submit = async (e) => {
    e?.preventDefault();
    if (!userData?.token) {
      return alert("You are un authorized");
    }
    if (!data || !data?.image) {
      console.log(data);
      return alert("Fill Out Each Box..");
    }

    const newForm = new FormData();
    Object.keys(data)?.map((itm) => {
      return newForm.append(itm, data[itm]);
    });

    try {
      const res = await fetch(`${url}/add-post/${relation}`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${userData?.token}`,
        },
        body: newForm,
      });

      if (res.ok) {
        alert("Data Added !");
        return nav("/profile/" + page);
      } else {
        const msg = await res.json();
        return alert(msg?.msg);
      }
    } catch (error) {
      console.log(error);
      return alert("Internal Error");
    }
  };

  return (
    <form className="mainQuill" onSubmit={submit}>
      <input
        type="file"
        style={{ display: "none" }}
        ref={image}
        onChange={imageHandler}
      />
      <img
        src={blob || `/image/add.png`}
        alt=""
        style={{ width: "100%", cursor: "pouinter" }}
        onClick={() => {
          image?.current?.click();
        }}
      />

      <input
        type="text"
        style={{display:isReq===false?"none":"block"}}
        className="input"
        placeholder="Enter Title"
        value={data?.title}
        onChange={(e) =>
          setData((prev) => ({ ...prev, title: e?.target?.value }))
        }
        required={isReq ?? true}
      />

      {relation!=="products" && <JoditEditor
        value={data?.content}
        style={{ height: "400px", width: "100%" }}
        height="500px"
        onChange={(value) => {
          setData((prev) => ({ ...prev, content: value }));
        }}
      />}
      

      <br />
      <br />
      <button
        className="submit"
        style={{
          width: "calc(100% - 1rem)",
          fontSize: "3rem",
          boxShadow: "0 0 1rem black",
        }}
        type="submit"
      >
        Submit
      </button>
    </form>
  );
};
