import React, { useContext, useEffect, useState } from "react";
import { Changes } from "../../GlobalVars/GlobalVars";
import { getFunction } from "../../GlobalVars/GetFunction";
import { editUnits } from "../../GlobalVars/AdminPost";

export const AdFooter = () => {
  const [data, setData] = useState();
  const { change, userData } = useContext(Changes);
  const url = process.env.REACT_APP_SERVER;

  const [newData, setNewData] = useState({
    location: "",
    facebook: "",
    linkedin: "",
    twitter: "",
    youtube: "",
    phone: "",
    mail: "",
  });

  const objFormat = {
    location: "",
    facebook: "",
    linkedin: "",
    twitter: "",
    youtube: "",
    phone: "",
    mail: "",
  };

  useEffect(() => {
    getFunction("footer", setData, url);
  }, [change, url]);

  useEffect(() => {
    if (data && Object.keys(data?.unstructs)?.length > 0) {
      for (let key in data?.unstructs) {
        if (Object.keys(objFormat).includes(key)) {
          setNewData((prev) => ({ ...prev, [key]: data?.unstructs[key] }));
        }
      }
    }
  }, [data]);

  const changeFn = async (e) => {
    const { name, value } = e?.target;
    return setNewData((prev) => ({ ...prev, [name]: value }));
  };

  const submit = async (e) => {
    e.preventDefault();
    await editUnits("footer", newData, userData?.token);
    return alert("Changes Made!");
  };

  return (
    <div className="adminEdit">
      <form className="footerEdit" onSubmit={submit}>
        <h1>Change Footer Informations</h1>
        <br />

        {/* location */}
        <b>Enter Location</b>
        <input
          type="text"
          className="input"
          id="location"
          name="location"
          value={newData?.location}
          onChange={changeFn}
        />
        <br />
        <br />
        <br />
        <br />
        {/* mail */}
        <b>Enter Mail</b>
        <input
          type="text"
          className="input"
          id="mail"
          name="mail"
          value={newData?.mail}
          onChange={changeFn}
        />
        <br />
        <br />
        {/* phone */}
        <b>Enter Phone Number</b>
        <input
          type="text"
          className="input"
          id="phone"
          name="phone"
          value={newData?.phone}
          onChange={changeFn}
        />
        <br />
        <br />
        <br />
        <br />
        {/* facebook */}
        <b>Enter Facebook link</b>
        <input
          type="text"
          className="input"
          id="facebook"
          name="facebook"
          value={newData?.facebook}
          onChange={changeFn}
        />
        <br />
        <br />
        {/* linked in */}
        <b>Enter LinkedIn link</b>
        <input
          type="text"
          className="input"
          id="linkedin"
          name="linkedin"
          value={newData?.linkedin}
          onChange={changeFn}
        />
        <br />
        <br />
        {/* facebook */}
        <b>Enter Youtube link</b>
        <input
          type="text"
          className="input"
          id="youtube"
          name="youtube"
          value={newData?.youtube}
          onChange={changeFn}
        />
        <br />
        <br />
        {/*twitterx */}
        <b>Enter Instagram link</b>
        <input
          type="text"
          className="input"
          id="twitter"
          name="twitter"
          value={newData?.twitter}
          onChange={changeFn}
        />
        <br />
        <br />
        <br />
        <br />
        <button type="submit" className="submit">
          Save Changes
        </button>
      </form>
    </div>
  );
};
