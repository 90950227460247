import React, { useState, useEffect, useContext } from "react";
import { AddBtn } from "./AddBtn";
import { deleteUnits } from "../GlobalVars/AdminPost";
import { MdDelete } from "react-icons/md";
import { Changes } from "../GlobalVars/GlobalVars";

export const OS = () => {
  const url = process.env.REACT_APP_SERVER;

  const [data, setData] = useState();
  const {setChange, userData, change} = useContext(Changes);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await fetch(`${url}/get-open_studio`);
        if (res.ok) {
          const parsed = await res.json();
          return setData(parsed);
        } else {
          throw new Error();
        }
      } catch (error) {
        return alert("Internal Error");
      }
    };

    getData();
  }, [url]);

  return (
    <div className="adminEdit">
    <div className="mainOsBox">
      <AddBtn linkTo={"/profile/add-open-studio"} style={{height:"calc(100vh - 8rem)", fontSize:"50rem"}}/>
      {/* map data */}
      {data?.map((item, idx) => (
        <div className="osBg" style={{backgroundImage:`url("${url}${item?.image}")`}} key={idx}>
           <button
        className="adminDel"
        onClick={async () => {
          await deleteUnits("os", item, userData?.token);
          return setChange(!change);
        }}
      >
        <MdDelete />
      </button>
          <div className="osTxt" dangerouslySetInnerHTML={{__html:item?.text}}></div>
        </div>
      ))}
    </div>
    </div>
  );
};
