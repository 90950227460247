import React, { useContext } from "react";
import "./change.css";
import { Changes } from "../../GlobalVars/GlobalVars";

export const ChangePwd = () => {
  const { userData } = useContext(Changes);

  const pwdChange = async (e) => {
    e.preventDefault();

    const obj = {
      oldP: e?.target?.oldP?.value,
      newP: e?.target?.newP?.value,
    };

    try {
      const res = await fetch(
        `${process.env.REACT_APP_SERVER}/change-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${userData?.token}`,
          },
          body: JSON.stringify(obj),
        }
      );

      if (res?.ok) {
        return alert("Password Changed");
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
      return alert("Something Went Wrong!");
    }
  };

  return (
    <form className="changePwd" onSubmit={pwdChange}>
      <h2>Change Password</h2>
      <br />
      <input
        type="password"
        placeholder="Enter Previous Password"
        className="input"
        id="oldP"
        required
      />
      <br />
      <br />
      <input
        type="text"
        placeholder="Enter New Password"
        id="newP"
        required
        className="input"
      />
      <br />
      <br />
      <input type="submit" className="submit" />
    </form>
  );
};
