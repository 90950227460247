import React from "react";
import "./reuse.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

export const TopSubBox = ({ title, image, topic, style }) => {

    const nav = useNavigate();

    console.log(topic)

  return (
    <div className="TopBox">
      <img src={image} alt="" style={{maxHeight:"70rem", objectFit:"cover"}}/>
      <br />
      <br/>
      <br/>
      <div className="pageTitle" style={style}>
        <button className="goback" onClick={()=>{nav(`/${topic}`)}}>
        <IoMdArrowRoundBack/> Back to {topic}
        </button>
        <br/>
        <h1 style={{textTransform:"Capitalize"}}>{topic}</h1>
        <h3>{title}</h3>
      </div>
    </div>
  );
};
