import React, { useContext, useEffect, useState } from "react";
import "./abtEdit.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { editUnits } from "../../GlobalVars/AdminPost";
import { Changes } from "../../GlobalVars/GlobalVars";

export const ChangeAbt = ({ data }) => {
  const [AbtItem, setAbtItem] = useState([]);
  const { userData } = useContext(Changes);

  const submit = async (e) => {
    e.preventDefault();
    await editUnits("about", AbtItem, userData?.token);
    return alert("Data Added")
  };

  useEffect(() => {
    if (Array.isArray(data)) {
      setAbtItem(data);
    }
  }, [data]);

  const titleChangeHandler = (e, idx) => {
    const { value } = e.target;
    setAbtItem((prevAbtItem) => {
      const updatedAbtItem = [...prevAbtItem];
      updatedAbtItem[idx] = {
        ...updatedAbtItem[idx],
        title: value,
      };
      return updatedAbtItem;
    });
  };

  const contentChangeHandler = (value, idx) => {
    setAbtItem((prevAbtItem) => {
      const updatedAbtItem = [...prevAbtItem];
      if (updatedAbtItem[idx]?.content !== value) {
        updatedAbtItem[idx] = {
          ...updatedAbtItem[idx],
          content: value,
        };
      }
      return updatedAbtItem;
    });
  };

  const deleteItem = (idx) => {
    const updatedAbtItem = AbtItem.filter((item, index) => index !== idx);
    setAbtItem(updatedAbtItem);
  };

  return (
    <form
      onSubmit={submit}
      className="changeAbtBox"
      style={{ width: "calc(80% - 4rem)", padding: "0 calc(10% + 2rem)" }}
    >
      {AbtItem?.map((item, idx) => (
        <div
          key={idx}
          className="abtContents"
          style={{ margin: "0 0 20rem 0" }}
        >
          <input
            type="text"
            className="abtAdminInput"
            value={item?.title}
            onChange={(e) => titleChangeHandler(e, idx)}
          />

          <ReactQuill
            theme="snow"
            style={{
              width: "calc(100% - 2rem)",
              margin: "3rem 1rem",
              height: "30rem",
            }}
            value={item?.content}
            onChange={(value) => contentChangeHandler(value, idx)}
          />
          <br />
          <br />
          <br />
          <button
            className="adminDel quillDel"
            type="button"
            style={{
              position: "unset",
              boxShadow: "none",
              margin: "2rem",
              fontSize: "2rem",
              padding: "1rem",
              cursor: "pointer",
            }}
            onClick={() => deleteItem(idx)}
          >
            Delete
          </button>
        </div>
      ))}

      <button
        className="btnAdd"
        type="button"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setAbtItem((prev) => [...prev, { title: "", content: "" }]);
        }}
      >
        Add New Comp
      </button>
      <button type="submit" className="btnAdd" style={{ cursor: "pointer" }}>
        Submit
      </button>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </form>
  );
};
