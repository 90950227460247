import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { HomeData } from "../GlobalVars/GlobalVars";
import "./home.css";

export const SlideImage = (url) => {
  const nav = useNavigate();
  const { data } = useContext(HomeData);
  console.log(data?.unstructs)
  return (
    <div className="mainSlide">
      <div className="slider">
        {data?.unstructs?.slides?.map((item, idx) => {
          return (
            <div
              className="slidingImage"
              key={idx}
              style={{ backgroundImage: `url("${url?.url}${item?.image}")` }}
              onClick={() => {
                if (item?.url) {
                  nav(item?.url);
                }
              }}
            ></div>
          );
        })}
      </div>
      <h1 className="slideTitle">{data?.unstructs?.title}</h1>
    </div>
  );
};
