import React, { useState } from "react";
import "./contact.css";
import { PostFunctions } from "../GlobalVars/PostFunctions";

export const ContactForm = () => {
  const inputs = ["name", "email", "company", "subject"];

  const [contact, setContact] = useState({
    name: "",
    email: "",
    company: "",
    subject: "",
    note: "",
  });

  const reset = async () => {
    setContact({
      name: "",
      email: "",
      company: "",
      subject: "",
      note: "",
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    PostFunctions("contact", contact, reset);
  };

  return (
    <form className="contactForm" onSubmit={submit}>
      {inputs?.map((data, index) => {
        return (
          <div className="inputBoxes" key={index}>
            {data}
            <br />
            <input
              className="input"
              name={data}
              type={data === "email" ? "email" : "text"}
              id={data}
              value={contact[data]}
              onChange={(e) => {
                const { name, value } = e?.target;
                setContact((prev) => ({ ...prev, [name]: value }));
              }}
              required={data === "company" ? false : true}
            />
          </div>
        );
      })}
      <div className="inputBoxes">
        note <br />
        <textarea
          name="note"
          id="note"
          className="input"
          value={contact?.note}
          onChange={(e) => {
            const { name, value } = e?.target;
            setContact((prev) => ({ ...prev, [name]: value }));
          }}
        />
      </div>
      <input type="submit" className="submit" />
    </form>
  );
};
