import React, { useContext, useEffect, useState } from "react";
import { Changes } from "../../GlobalVars/GlobalVars";
import "./editor.css";

export const EditUsers = () => {
  const [data, setData] = useState([]);
  console.log(data);
  const url = process.env.REACT_APP_SERVER;
  const { change, userData } = useContext(Changes);

  useEffect(() => {
    const getFn = async () => {
      try {
        const res = await fetch(url + "/get-users", {
          method: "GET",
          headers: {
            authorization: `Bearer ${userData?.token}`,
          },
        });
        if (res.ok) {
          const parsed = await res.json();
          setData(parsed);
        } else {
          throw new Error();
        }
      } catch (err) {
        return alert("Error Occured");
      }
    };

    getFn();
  }, [url, change, userData]);

  const updateUser = async (e, _id) => {
    e.preventDefault();
    const obj = {
      price: e?.target?.price?.value,
      files: e?.target?.files?.value,
      status: e?.target?.status?.value,
    };

    try {
      const res = await fetch(`${url}/edit-user-detail/${_id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify(obj),
      });

      if (!res.ok) {
        throw new Error();
      } else {
        return alert("Data Edited Successfully!");
      }
    } catch (err) {
      return alert("Internal Server Error!");
    }
  };

  return (
    <div className="adminEdit">
      {data?.map((item, idx) => (
        <form key={idx} className="userInfo" onSubmit={(e)=>updateUser(e, item?._id)}>
          <div className="userDataHolding">
            <div className="profileImage">{item?.email?.split("")[0]}</div>
            <font>{item?.email}</font>
          </div>
          <br />
          <br />
          <b>Price : </b>
          <input
            type="text"
            className="input"
            placeholder="Enter Link for price-rates"
            id="price"
            defaultValue={item?.price}
          />
          <br />
          <br />
          <b>Files : </b>
          <input
            type="text"
            className="input"
            placeholder="Enter Link for files"
            id="files"
            defaultValue={item?.files}
          />
          <br />
          <br />
          <b>Status : </b>
          <input
            type="text"
            className="input"
            placeholder="Enter Link for order status"
            id="status"
            defaultValue={item?.status}
          />
          <br />
          <br />
          <input type="submit" value="Submit" className="submit" />
        </form>
      ))}
    </div>
  );
};
